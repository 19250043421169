import React from "react";
import DangerGrid from "./danger-grid";
import DangerDetail from "./danger-detail";
import DangerToolbar from "./danger-toolbar";
import AuthorizedComponent from "../authorized-component";
import { enums, alertError, confirm2, notifySuccess, authorizationService, globalPopupManager, globalPopupNames, baseApiUrl, download, prompt2 } from "../../base";
import { dangerService, employeeService, customerService } from "../../api";
import { AssignEmployeePopup, ContentBlock, DangerAuditPopup, IssueDangerPopup } from "../../components";
import { DangerBatchModel, DangerModel, EmployeeRef } from "../../models";
import DangerEditForm from "./danger-edit-form";

export default class DangerPage extends AuthorizedComponent {
    objectName = "Danger";
    detailRef = React.createRef<DangerDetail>();
    assignRef = React.createRef<AssignEmployeePopup>();
    issueRef = React.createRef<IssueDangerPopup>();
    gridRef = React.createRef<DangerGrid>();
    toolbarRef = React.createRef<DangerToolbar>();
    auditRef = React.createRef<DangerAuditPopup>();
    formRef = React.createRef<DangerEditForm>();
    dangerOfAssign: DangerModel = {};

    constructor(props: any) {
        super(props);

        this.onDangerSelected = this.onDangerSelected.bind(this);
        this.onRowChecked = this.onRowChecked.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onContextButtonClick = this.onContextButtonClick.bind(this);
        this.getHandlers = this.getHandlers.bind(this);
        this.assignHandler = this.assignHandler.bind(this);
        this.onSaved = this.onSaved.bind(this);
        this.filter = this.filter.bind(this);
    }

    get actionNames(): string[] {
        return [
            enums.stdActions.update,
            enums.stdActions.delete,
            "Issue",
            "Assign",
            "Audit"
        ];
    }

    async authorize(): Promise<void> {
        await super.authorize();

        this.permissions["LockCustomer"] = await authorizationService.authorize("Customer", "Lock");
        this.setState({});
    }

    onDangerSelected(danger: DangerModel) {
        this.toolbarRef.current?.setModel(danger);
    }

    onRowChecked(dangers: DangerBatchModel[]) {
        this.toolbarRef.current?.setBatchModels(dangers);
    }

    filter(filterValue: any, showDeleted: boolean, key: string) {
        this.gridRef.current?.filter(filterValue, showDeleted, key);
    }

    onButtonClick(danger: DangerModel, name: string) {
        switch (name) {
            case "callcenter":
                globalPopupManager.show(globalPopupNames.callCenter, { id: danger.customerId });
                break;

            case "issue":
                if (this.permissions["Issue"]) {
                    this.issueRef.current?.show();
                }
                break;

            case "detail":
                this.detailRef.current?.show(danger);
                break;

            case "assign":
                if (this.permissions["Assign"]) {
                    this.showEmployeePopup(danger);
                }
                break;

            case "audit":
                if (this.permissions["Audit"]) {
                    this.showAuditPopup(danger);
                }
                break;

            case "switchLock":
                if (this.permissions["LockCustomer"]) {
                    this.switchLock(danger);
                }
                break;

            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.formRef.current?.show(danger);
                }
                break;

            case "notify":
                if (this.permissions["Assign"]) {
                    this.notify(danger);
                }
                break;

            case "physicaldelete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.physicalDelete(danger);
                }
        }
    }

    async onContextButtonClick(dangers: DangerBatchModel[], name: string) {
        switch (name) {
            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(dangers);
                }
                break;

            case "restore":
                if (this.permissions[enums.stdActions.delete]) {
                    this.restore(dangers);
                }
                break;

            case "export":
                if (!await confirm2("确定导出选中的隐患整改吗？")) {
                    return;
                }

                const url = `${baseApiUrl}${dangerService.apiPath}/exportform?ids=${dangers.map(d => d.id).join(',')}`;
                download(url, `隐患整改单.xls`);
                break;
        }
    }

    showEmployeePopup(danger: DangerModel) {
        this.dangerOfAssign = danger;
        this.assignRef.current?.show(danger.handlerId);
    }

    showAuditPopup(danger: DangerModel) {
        this.auditRef.current?.show(danger.id!);
    }

    async getHandlers() {
        const handlers = await employeeService
            .getRefs(enums.employeePosts.deliver, enums.employeePosts.securityInspector);

        return handlers.filter(c => c.organizationId === this.dangerOfAssign.organizationId);
    }

    async assignHandler(employee: EmployeeRef) {
        const result = await dangerService.assign(this.dangerOfAssign.id!, employee.id!);

        if (result.succeeded) {
            notifySuccess("成功分配送气工/安检员");
            this.assignRef.current?.hide();
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async switchLock(danger: DangerModel) {
        const locked = danger.customer!.locked,
            action = locked ? "解锁" : "锁定";

        const note = await prompt2(`确定要${action}该客户吗？请输入备注（可选）`);

        if (note === undefined) {
            return;
        }

        const result = await customerService.switchLock(danger.customerId!, note);

        if (result.succeeded) {
            notifySuccess(`已成功${action}客户`);
            await this.gridRef.current?.refresh();
            this.toolbarRef.current?.setModel(this.gridRef.current?.getSelectedDanger());
        } else {
            alertError(result.message!);
        }
    }

    async restore(dangers: DangerBatchModel[]) {
        if (!await confirm2("确定要还原选中的数据吗？")) {
            return;
        }

        const result = await dangerService.batchRestore(dangers.map(d => d.id));

        if (result.succeeded) {
            notifySuccess("已成功还原数据");
            this.gridRef.current?.clearSelection();
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async delete(dangers: DangerBatchModel[]) {
        if (!await confirm2("确定要作废选中的隐患吗？")) {
            return;
        }

        const result = await dangerService.batchDelete(dangers.map(d => d.id));

        if (result.succeeded) {
            notifySuccess("已成功作废隐患");
            this.gridRef.current?.clearSelection();
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }
    
    async physicalDelete(danger: DangerModel) {
        if (!await confirm2("确定要彻底删除选中的隐患吗？（彻底删除的数据无法还原）")) {
            return;
        }

        const result = await dangerService.physicalDelete(danger.id!);

        if (result.succeeded) {
            notifySuccess("已成功删除隐患");
            this.gridRef.current?.clearSelection();
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async notify(danger: DangerModel) {
        if (!danger.handlerId) {
            alertError("尚未分配处理人员，无法提醒");
        } else if (!await confirm2("确定要再次发送提醒吗")) {
            return;
        }

        const result = await dangerService.notify(danger.id!);

        if (result.succeeded) {
            notifySuccess("已成功发送提醒");
        } else {
            alertError(result.message!);
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return (
            <ContentBlock caption={"隐患整改"}>
                <DangerToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onFiltering={this.filter}
                    onButtonClick={this.onButtonClick}
                    onContextButtonClick={this.onContextButtonClick} />
                <DangerGrid
                    permissions={this.permissions}
                    ref={this.gridRef}
                    onDangerSelected={this.onDangerSelected}
                    onRowChecked={this.onRowChecked}
                    onButtonClick={this.onButtonClick} />
                <DangerDetail ref={this.detailRef} />
                <AssignEmployeePopup
                    ref={this.assignRef}
                    getFunc={this.getHandlers}
                    save={this.assignHandler} />
                <IssueDangerPopup ref={this.issueRef} onSaved={this.onSaved} />
                <DangerAuditPopup ref={this.auditRef} onSaved={this.onSaved} />
                <DangerEditForm ref={this.formRef} onSaved={this.onSaved} />
            </ContentBlock>
        );
    }
}