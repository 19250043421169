import React from "react";
import { Popup, TagBox } from "devextreme-react";
import Form, { IItemProps } from "devextreme-react/form";
import DataSource from "devextreme/data/data_source";
import { ValueChangedEvent as TagValueChangedEvent } from "devextreme/ui/tag_box";
import { stdSeparator, alertError, notifySuccess, dataSources, alertWarning, authorizationService } from "../../base";
import { FormMultiImageUploader, PopupContentBox, RefPopup, StdForm } from "../../components";
import { customerService, customerTypeService, serverDataService } from "../../api";
import { emptyDataSources, DataSources, loadCustomerDataSources } from "../../api/data-source-helper";
import { CustomerModel, CustomerTypeRef } from "../../models";

export interface CustomerFormProps {
    onSaved: () => void;
}

interface CustomerFormState {
    dataSources: DataSources;
    filteredTypes: CustomerTypeRef[];
    editTypePermission: boolean;
}

export default class CustomerForm extends React.Component<CustomerFormProps, CustomerFormState> {
    moduleName = "Customer";
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    customer: CustomerModel = {};
    typeId: number = 0;
    items: IItemProps[];

    constructor(props: Readonly<CustomerFormProps>) {
        super(props);

        this.state = {
            dataSources: emptyDataSources(),
            filteredTypes: [],
            editTypePermission: false
        };

        this.onCountyChanged = this.onCountyChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.clearCustomer = this.clearCustomer.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);

        this.items = [
            { dataField: 'gasCode', editorOptions: { readOnly: false } },
            { dataField: 'name' },
            {
                dataField: 'typeId',
                editorType: 'dxSelectBox',
                editorOptions: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
            },
            {
                dataField: 'status',
                disabled: true,
                editorType: 'dxSelectBox',
                editorOptions: { dataSource: dataSources.customerStatus, displayExpr: 'text', valueExpr: 'value' }
            },
            { dataField: 'phoneNumber' },
            { dataField: 'phoneNumber2' },
            {
                dataField: 'areaId',
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    displayExpr: 'name',
                    valueExpr: 'id',
                    onValueChanged: this.onCountyChanged
                }
            },
            {
                dataField: 'streetId',
                editorType: 'dxSelectBox',
                editorOptions: { dataSource: [], displayExpr: 'name', valueExpr: 'id' },
                validationRules: [{ type: "required", message: "街道不能为空" }]
            },
            { dataField: 'address' },
            { dataField: 'personInCharge' },
            { dataField: 'note', colSpan: 2 },
            { dataField: 'idNumber' },
            { dataField: 'businessLicense' },
            {
                dataField: 'addressType',
                editorType: 'dxSelectBox',
                editorOptions: { dataSources: [] }
            },
            {
                dataField: 'organizationId',
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    displayExpr: 'name',
                    valueExpr: 'id',
                    onValueChanged: this.onOrganizationChanged
                },
                validationRules: [{ type: "required", message: "站点不能为空" }]
            },
            {
                dataField: 'deliverId',
                editorType: 'dxSelectBox',
                editorOptions: { dataSource: [], displayExpr: 'nameAndCode', valueExpr: 'id', searchEnabled: true }
            },
            { dataField: 'contractNumber' },
            { dataField: 'contractDate', editorType: "dxDateBox" },
            {
                dataField: 'contractPhoto',
                label: { text: "合同照片" },
                render: (e: any) => {
                    return (
                        <FormMultiImageUploader
                            formData={this.customer}
                            dataField={"contractPhoto"}
                            sourceField={"contractPhotoSource"}
                            id={"contractPhoto"} />
                    )
                }
            },
            { dataField: 'code' },
            {
                dataField: 'idNoPhoto',
                label: { text: "证件照片" },
                render: (e: any) => {
                    return (
                        <FormMultiImageUploader
                            formData={this.customer}
                            dataField={"idNoPhoto"}
                            sourceField={"idNoPhotoSource"}
                            id={"idNoPhoto"} />
                    )
                }
            },
            { 
                dataField: 'alerter',
                editorType: 'dxSelectBox',
                editorOptions: { 
                    dataSource: dataSources.alerterStatus, 
                    displayExpr: 'text', 
                    valueExpr: 'value',
                    showClearButton: true 
                }
            },
            { dataField: 'alerterNumber' },
            { 
                dataField: 'isolatedStoreroom',
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: dataSources.trueFalseList,
                    displayExpr: 'text',
                    valueExpr: 'value',
                    showClearButton: true
                } 
            },
            {
                dataField: 'stdAddressPhoto',
                label: { text: "标准地址牌照" },
                render: (e: any) => {
                    return (
                        <FormMultiImageUploader
                            formData={this.customer}
                            dataField={"stdAddressPhoto"}
                            sourceField={"stdAddressPhotoSource"}
                            id={"stdAddressPhoto"} />
                    )
                }
            },
            ...serverDataService.bottleTypes.map((t: any) => ({
                dataField: `discount_${t.id}`,
                label: { text: `${t.name}优惠折扣` }
            })),
            {
                dataField: 'label',
                colSpan: 2,
                render: (args: any) => {
                    const defaultValue = this.customer.labels ? this.customer.labels.map((l: any) => l.labelId) : [];
                    const store = new DataSource({
                        store: this.state.dataSources.labels,
                        key: 'id',
                        group: 'groupName'
                    });

                    return (
                        <TagBox
                            dataSource={store}
                            defaultValue={defaultValue}
                            valueExpr={"id"}
                            displayExpr={"labelText"}
                            grouped={true}
                            groupRender={(e: any) => e.key || "未分类"}
                            onValueChanged={this.onTagValueChanged.bind(this)}
                        />
                    )
                }
            },
        ]
    }

    componentDidMount() {
        this.loadDataSources();
    }

    async loadDataSources() {
        const dataSources = await loadCustomerDataSources();
        const filteredTypes = await customerTypeService.getLimitRefs();

        this.setDataSource({
            "typeId": dataSources.types,
            "areaId": dataSources.counties,
            "organizationId": dataSources.orgs,
            "addressType": dataSources.addressTypes
        });

        const editTypePermission = await authorizationService.authorize("Customer", "TypeEdit");

        this.setState({ 
            dataSources: dataSources, 
            filteredTypes: filteredTypes,
            editTypePermission: editTypePermission 
        });
    }

    setDataSource(map: { [key: string]: any[] }) {
        for (const key in map) {
            const options = this.items.find(i => i.dataField === key)!.editorOptions;
            options.dataSource = map[key];
        }
    }

    show(customer: CustomerModel) {
        this.popupRef.current &&
            this.popupRef.current.instance.show();

        this.setCustomer(customer);
    }

    async setCustomer(customer: CustomerModel) {
        if ((customer.id || 0) > 0) {
            customer = await customerService.get(customer.id!);
        }

        this.customer = customer;
        this.typeId = customer.typeId || 0;
        this.setStreets();
        this.setDelivers();
        this.setDiscounts();

        const isNew = !this.customer.id;
        const typeOptions = this.items.find(i => i.dataField === "typeId")!.editorOptions;
        this.items.find(i => i.dataField === "gasCode")!.editorOptions.readOnly = !isNew;

        if (isNew) {
            typeOptions.dataSource = this.state.filteredTypes;
            typeOptions.disabled = false;
        } else {
            if (!this.state.editTypePermission) {
                typeOptions.disabled = true;
                typeOptions.dataSource = this.state.dataSources.types;
            } else {
                typeOptions.disabled = !this.state.filteredTypes.find(t => t.id === customer.typeId);
                typeOptions.dataSource = typeOptions.disabled ? this.state.dataSources.types : this.state.filteredTypes;
            }
        }

        this.setState({});
    }

    clearCustomer() {
        this.setCustomer({});
    }

    hide() {
        this.popupRef.current &&
            this.popupRef.current.instance.hide();
    }

    setStreets() {
        const filteredStreets = this.state.dataSources.streets
            .filter(s => s.parentId === this.customer.areaId);

        if (!filteredStreets.find(s => s.id === this.customer.streetId)) {
            this.customer.streetId = undefined;
        }

        this.setDataSource({ "streetId": filteredStreets });
    }

    setDelivers() {
        const filteredDelivers = this.state.dataSources.employees
            .filter(d => d.organizationId === this.customer.organizationId);

        if (!filteredDelivers.find(d => d.id === this.customer.deliverId)) {
            this.customer.deliverId = undefined;
        }

        this.setDataSource({ "deliverId": filteredDelivers });
    }

    setDiscounts() {
        const customer: any = this.customer;

        for (const discount of customer.discounts || []) {
            customer[`discount_${discount.bottleTypeId}`] = discount.discountValue;
        }
    }

    onCountyChanged() {
        this.setStreets();
        this.setState({});
    }

    onOrganizationChanged() {
        this.setDelivers();
        this.setState({});
    }

    onTagValueChanged(e: TagValueChangedEvent) {
        const groups = e.value
            .filter((v: number) => e.previousValue.indexOf(v) === -1)
            .map((v: number) => this.state.dataSources.labels.find(l => l.id === v)?.groupName)
            .filter((g: string) => g);

        const toRemove = e.previousValue
            .map((v: number) => this.state.dataSources.labels.find(l => l.id === v))
            .filter((l: any) => groups.indexOf(l.groupName) !== -1)
            .map((l: any) => l.id);

        const newValues = e.value.filter((v: number) => toRemove.indexOf(v) === -1);

        const labels = this.state.dataSources.labels.filter(l => newValues.indexOf(l.id) !== -1);

        const text = labels.map(l => l.labelText).join(stdSeparator);

        this.customer.label = text;
        this.customer.labels = labels.map(l => ({ customerId: this.customer.id, labelId: l.id }));

        // 会再次引发事件，一定要判断条件
        if (toRemove.length > 0) {
            e.component.option("value", newValues);
        }
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (!isValid?.isValid) {
            return;
        }

        const customer = this.customer;
        const customerAny: any = this.customer;
        const customerType = this.state.dataSources.types.find(t => t.id === this.customer.typeId);
        if (customerType?.label?.indexOf('居民') === -1 && !this.customer.personInCharge) {
            alertWarning("除居民用户外，其他类型用户均需要提供负责人信息");
            return;
        }
        
        // 优惠项
        if (!customer.discounts) {
            customer.discounts = [];
        }

        for (const key in customerAny) {
            if (key.startsWith("discount_")) {
                const bottleTypeId = parseInt(key.substring("discount_".length));
                const index = customer.discounts!.findIndex((d: any) => d.bottleTypeId === bottleTypeId);
                let discount = index >= 0 ? customer.discounts[index] : null;
                let value = customerAny[key];

                if (value) {
                    if (!discount) {
                        discount = { customerId: customer.id, bottleTypeId: bottleTypeId };
                        customer.discounts.push(discount);
                    }

                    discount.discountValue = customerAny[key];                    
                } else if (index >= 0) {
                    customer.discounts!.splice(index, 1);
                }
            }
        }

        const res = customer.id ?
            await customerService.update(customer) :
            await customerService.create(customer);

        if (res.succeeded) {
            notifySuccess("已成功保存客户信息");
            this.hide();
            this.props.onSaved();
        } else {
            alertError(res.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"编辑"}
                width={1000}
                height={600}
                baseOptions={{ onHidden: this.clearCustomer }}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.items}
                        formData={this.customer} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}

