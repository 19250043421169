import { createStoreFromUrl, http, MessageBool } from "../base";
import { DangerAuditModel, DangerModel, UnfixedDangerModel } from "../models";

class DangerService {
    apiPath = '/api/danger';

    getDxData(key?: string) {
        return createStoreFromUrl(`${this.apiPath}/dx?key=${key || ''}`, 'id');
    }

    getDeletedDxData(key?: string) {
        return createStoreFromUrl(`${this.apiPath}/deleteddx?key=${key || ''}`, 'id');
    }

    getNotificationDxData() {
        return createStoreFromUrl(`${this.apiPath}/notification/dx`, 'id');
    }

    get(id: number) {
        return http.getData(`${this.apiPath}/detail/${id}`);
    }

    getUnfixedByCustomerId(customerId: number) {
        return http.getData<UnfixedDangerModel[]>(`${this.apiPath}/unfixed/${customerId}`);
    }

    create(model: DangerModel) {
        return http.postData<MessageBool>(this.apiPath, model);
    }

    update(model: DangerModel) {
        return http.putData<MessageBool>(this.apiPath, model);
    }

    assign(id: number, employeeId: string) {
        return http.putData<MessageBool>(
            `${this.apiPath}/assign/${id}?employeeId=${employeeId}`, '');
    }

    audit(model: DangerAuditModel) {
        return http.putData<MessageBool>(`${this.apiPath}/audit`, model);
    }

    restore(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/restore/${id}`, '');
    }

    delete(id: number) {
        return http.deleteData<MessageBool>(`${this.apiPath}/${id}`);
    }

    physicalDelete(id: number) {
        return http.deleteData<MessageBool>(`${this.apiPath}/physical/${id}`);
    }

    notify(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/notify/${id}`, '');
    }

    batchDelete(ids: number[]) {
        return http.putData<MessageBool>(`${this.apiPath}/batchdelete`, ids);
    }

    batchRestore(ids: number[]) {
        return http.putData<MessageBool>(`${this.apiPath}/batchrestore`, ids);
    }
}

export const dangerService = new DangerService();
export const dangerNotificationStore = dangerService.getNotificationDxData();