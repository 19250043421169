import { createStoreFromApi, http, BaseApiService } from "../base";
import { CustomerTypeModel, CustomerTypeRef } from "../models";

class CustomerTypeService extends BaseApiService<CustomerTypeModel> {
    moduleName = "customertype";
    cacheSeconds = 1200;

    getRefs(): Promise<CustomerTypeRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/refs`), this.cacheSeconds, false, "refs");
    }

    getLimitRefs(): Promise<CustomerTypeRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/limitrefs`), this.cacheSeconds, true, "limitrefs")
    }
}

export const customerTypeService = new CustomerTypeService();
export const customerTypeStore = createStoreFromApi(customerTypeService);